import { Theme } from '@/app'

const appRowHeight = 'max(660px, min(102vh, 1200px))'
const appRowHeightMobile = 'auto'

export const styles = {
  appRow: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    zIndex: 1,
    maxWidth: '100%',
    ...Theme.center,
    [Theme.media.down('mid')]: {
      flexDirection: 'column',
      marginTop: Theme.spacing(6),
      marginBottom: Theme.spacing(6),
    },
  },
  appRowLeft: {
    flexDirection: 'row-reverse',
  },
  appRowCenterWrapper: {
    overflow: 'visible',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: appRowHeight,
    position: 'relative',
    [Theme.media.down('mid')]: {
      height: appRowHeightMobile,
    },
  },
  appDescriptionWrapper: {
    paddingRight: Theme.spacing(9),
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxWidth: 'min(35%, 500px)',
    [Theme.media.down('large')]: {
      paddingRight: Theme.spacing(6),
      maxWidth: 'min(45%, 500px)',
    },
    [Theme.media.down('mid')]: {
      ...Theme.flex,
      ...Theme.center,
      paddingRight: 0,
      maxWidth: '100%',
      flexDirection: 'column-reverse',
      marginBottom: Theme.spacing(1),
    },
  },
  appDescriptionWrapperLeft: {
    paddingRight: 0,
    paddingLeft: Theme.spacing(9),
    [Theme.media.down('large')]: {
      paddingLeft: Theme.spacing(6),
      paddingRight: 0,
    },
    [Theme.media.down('mid')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  backgroundImageWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'visible',
    ...Theme.center,
    ...Theme.flex,
  },
  backgroundImage: {
    borderRadius: Theme.values.borderRadius.small * 3,
    overflow: 'hidden',
    maxWidth: 'min(95vw, 1500px)',
    maxHeight: 'max(520px, min(75vh, 900px))',
    height: '100vh',
    width: '100vw',
    [Theme.media.down('large')]: {
      maxWidth: 'min(95vw, 1500px)',
      maxHeight: 'max(520px, min(75vh, 700px))',
    },
    [Theme.media.down('mid')]: {
      height: `calc(100% - ${Theme.spacing(4)}px)`,
      maxHeight: 'unset',
    },
  },
  imageInnerWrapper: {
    ...Theme.center,
    ...Theme.flex,
    height: '100%',
    position: 'relative',
  },
  buttonWrapper: {
    flex: 1,
    display: 'flex',
    marginTop: Theme.spacing(1),
    [Theme.media.down('mid')]: {
      marginTop: 0,
      marginBottom: Theme.spacing(2),
    },
  },
  appStoreLogosWrapper: {
    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(2),
    },
  },
  appStoreLogos: {
    height: 40,
    width: 40,
    marginBottom: Theme.spacing(3),
    marginRight: Theme.spacing(2),
  },
  appLogo: {
    height: 60,
    width: 60,
    marginBottom: Theme.spacing(3),
  },
  companiesLogo: {
    height: 80,
    width: 80,
    marginBottom: Theme.spacing(2),
    marginRight: Theme.spacing(2),
    [Theme.media.down('large')]: {
      width: 40,
      height: 40,
    },
  },
}
