import { Icons } from './icons'
import { Logos } from './logos'
import { Misc } from './misc'
import { Portfolio } from './portfolio'
import { Services } from './services'
import { Team } from './team'
import { Videos } from './videos'

export const Assets = {
  Logos,
  Portfolio,
  Services,
  Misc,
  Team,
  Videos,
  Icons,
}
