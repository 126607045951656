import React from 'react'
import { Image, Tools, View } from '@/lib'
import { Theme } from '@/app'
import { DemoDevice, GeneralCardProps, Reveal } from '@/components'
import { Assets } from '@/assets'
import { apps } from '../data'
import CardComponents from './components'

const AWSCloudroomCard = (props: GeneralCardProps) => {

  const {
    onSelect,
    position,
  } = props

  const { height } = Theme.hooks.size()
  const isLargeish = Theme.hooks.down('largeish')

  const isMobile = Theme.hooks.down('mid')
  const limitedPerformance = Tools.useLimitedPerformance()

  const data = apps['cloudroom-web']

  return (
    <CardComponents.AppRow
      elemName={'cloudroom'}
      background={data.background}
      position={position}
    >
      <CardComponents.AppDescription data={data} position={position}/>
      {isLargeish ? (
        <DemoDevice.DemoRow data={data} onSelect={onSelect}/>
      ) : (
        <Reveal
          variant={'parallaxReverse'}
          amount={'15vh'}
          offset={height * -0.35}
          duration={height * 1.1}
          stopProgressAt={0.55}
          disabled={limitedPerformance > 0 || isMobile}
        >
          <div className={'willChangeTransform'}>
            <View style={styles.tabletWrapper}>
              <Image
                loading={'eager'}
                src={Assets.Portfolio.AWSCloudroom['background-tablet']}
                style={styles.image}
              />
            </View>
          </div>
        </Reveal>
      )}
    </CardComponents.AppRow>
  )
}

const styles = {
  tabletWrapper: {
    width: `calc(0.6 * min(95vw, 1500px))`,
    height: 'calc(0.7 * max(520px, min(75vh, 900px)))',
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    [Theme.media.down('xlarge')]: {
      height: 'calc(0.75 * max(520px, min(75vh, 900px)))',
      width: `calc(0.5 * min(95vw, 1500px))`,
    },
    [Theme.media.down('large')]: {
      width: `calc(0.6 * min(95vw, 1500px))`,
    },
    [Theme.media.down('largeish')]: {
      height: 'calc(0.6 * max(520px, min(75vh, 900px)))',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.medium,
    objectFit: 'fill',
  },
}

export default AWSCloudroomCard
