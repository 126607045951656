import React from 'react'
import { Image, Tools, View } from '@/lib'
import { Theme } from '@/app'
import { DemoDevice, GeneralCardProps, Reveal } from '@/components'
import { Assets } from '@/assets'
import { apps } from '../data'
import CardComponents from './components'

const KiteWebCard = (props: GeneralCardProps) => {

  const {
    onSelect,
    position = 'right',
  } = props

  const { height } = Theme.hooks.size()

  const isLargeish = Theme.hooks.down('largeish')
  const upLargeish = Theme.hooks.up('largeish')

  const isMobile = Theme.hooks.down('mid')

  const limitedPerformance = Tools.useLimitedPerformance()

  const data = apps['kite-web']

  return (
    <CardComponents.AppRow
      elemName={'kite'}
      background={data.background}
      position={position}
    >
      <CardComponents.AppDescription data={data} position={position}/>
      {isLargeish ? (
        <DemoDevice.DemoRow data={data} onSelect={onSelect}/>
      ) : (
        <>
          <Reveal
            variant={'parallaxReverse'}
            amount={'15vh'}
            offset={height * -0.3}
            duration={height * 1.1}
            stopProgressAt={0.8}
            disabled={isMobile || limitedPerformance > 0}
          >
            <div className={'willChangeTransform'}>
              <View style={styles.tabletWrapper}>
                <Image
                  loading={'eager'}
                  src={Assets.Portfolio.Kite.web['background-tablet-2']}
                  style={styles.image}
                />
              </View>
            </div>
          </Reveal>
          {upLargeish ? (
            <Reveal
              variant={'parallaxBackground'}
              amount={'25vh'}
              offset={height * -0.5}
              duration={height * 1.5}
              disabled={isMobile || limitedPerformance > 0}
              stopProgressAt={0.25}
            >
              <div className={'willChangeTransform'}>
                <View style={styles.squareImageWrapper}>
                  <Image
                    loading={'eager'}
                    src={Assets.Portfolio.Kite.web['background-square']}
                    style={styles.image}
                  />
                </View>
              </div>
            </Reveal>
          ) : null}
        </>
      )}
    </CardComponents.AppRow>
  )

}

const styles = {
  tabletWrapper: {
    width: `calc(0.45 * min(95vw, 1500px))`,
    height: 'calc(0.75 * max(520px, min(75vh, 900px)))',
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    backgroundColor: Theme.colors.white,
    [Theme.media.down('xlarge')]: {
      width: `calc(0.5 * min(95vw, 1500px))`,
    },
    [Theme.media.down('large')]: {
      width: `calc(0.6 * min(95vw, 1500px))`,
    },
    [Theme.media.down('largeish')]: {
      height: 'calc(0.6 * max(520px, min(75vh, 900px)))',
    },
  },
  squareImageWrapper: {
    width: `calc(0.2 * min(95vw, 1500px))`,
    height: 'calc(0.45 * max(520px, min(75vh, 900px)))',
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    position: 'absolute',
    backgroundColor: Theme.colors.white,
    left: '-250px',
    [Theme.media.down('xlarge')]: {
      left: '-220px',
    },
    [Theme.media.down('large')]: {
      left: '-220px',
      width: `calc(0.22 * min(95vw, 1500px))`,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.medium,
    objectFit: 'cover',
  },
}

export default KiteWebCard
