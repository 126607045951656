import React from 'react'
import { Image, Tools, View } from '@/lib'
import { Theme } from '@/app'
import { DemoDevice, GeneralCardProps, Reveal } from '@/components'
import { Assets } from '@/assets'
import { apps } from '../data'
import CardComponents from './components'

const KiteGeneralCard = (props: GeneralCardProps) => {

  const {
    onSelect,
    position = 'right',
  } = props

  const { height } = Theme.hooks.size()

  const isLargeish = Theme.hooks.down('largeish')
  const upxxLarge = Theme.hooks.up('xxlarge')

  const isMobile = Theme.hooks.down('mid')

  const limitedPerformance = Tools.useLimitedPerformance()

  const data = apps['kite-general']

  return (
    <CardComponents.AppRow
      elemName={'kite'}
      background={data.background}
      position={position}
    >
      <View style={styles.wrapper}>
        <CardComponents.AppDescription data={data} position={position}/>
        {isLargeish ? (
          <DemoDevice.DemoRow data={data} onSelect={onSelect}/>
        ) : (
          <>
            <Reveal
              variant={'parallaxReverse'}
              amount={'22vh'}
              offset={height * -(upxxLarge ? 0.3 : 0.1)}
              duration={height * 1.1}
              stopProgressAt={upxxLarge ? 0.5 : 0.5}
              disabled={isMobile || limitedPerformance > 0}
            >
              <div className={'willChangeTransform'}>
                <View style={styles.tabletWrapper}>
                  <Image
                    loading={'eager'}
                    src={Assets.Portfolio.Kite.web['background-tablet-1']}
                    style={styles.image}
                  />
                </View>
              </div>
            </Reveal>
            <Reveal
              variant={'parallaxBackground'}
              amount={'10vh'}
              offset={height * -(upxxLarge ? 0.75 : 0.1)}
              duration={height * 1.5}
              disabled={isMobile || limitedPerformance > 0}
              stopProgressAt={upxxLarge ? 0.8 : 0.25}
            >
              <div className={'willChangeTransform'}>
                <DemoDevice.Demo
                  alt={`${data.title} demo 2`}
                  image={Assets.Portfolio.Kite.mobile[1]}
                  style={styles.device}
                  scale={0.5}
                />
              </div>
            </Reveal>
          </>

        )}
      </View>
    </CardComponents.AppRow>
  )

}

const styles = {
  wrapper: {
    [Theme.media.up('mid')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  tabletWrapper: {
    width: `calc(0.4 * min(95vw, 1500px))`,
    height: 'calc(0.6 * max(520px, min(75vh, 900px)))',
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    backgroundColor: Theme.colors.white,
    [Theme.media.down('xlarge')]: {
      width: `calc(0.5 * min(95vw, 1500px))`,
    },
    [Theme.media.down('largeish')]: {
      height: 'calc(0.55 * max(520px, min(75vh, 900px)))',
    },
  },
  device: {
    position: 'absolute',
    left: -200,
    [Theme.media.down('largeish')]: {
      left: -220,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.medium,
    objectFit: 'fill',
  },
}

export default KiteGeneralCard
