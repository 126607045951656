import { ReactComponent as Mobile } from './mobile.svg'
import { ReactComponent as Web } from './web.svg'
import { ReactComponent as NextGen } from './next-gen.svg'
import { ReactComponent as AI } from './ai.svg'

export const Icons = {
  Web,
  Mobile,
  AI,
  NextGen,
}
