exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-post-tsx": () => import("./../../../src/pages/blog/_post.tsx" /* webpackChunkName: "component---src-pages-blog-post-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-dev-policies-tsx": () => import("./../../../src/pages/dev/policies.tsx" /* webpackChunkName: "component---src-pages-dev-policies-tsx" */),
  "component---src-pages-get-a-quote-index-tsx": () => import("./../../../src/pages/get-a-quote/index.tsx" /* webpackChunkName: "component---src-pages-get-a-quote-index-tsx" */),
  "component---src-pages-get-a-quote-success-tsx": () => import("./../../../src/pages/get-a-quote/success.tsx" /* webpackChunkName: "component---src-pages-get-a-quote-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lost-found-index-tsx": () => import("./../../../src/pages/lost_found/index.tsx" /* webpackChunkName: "component---src-pages-lost-found-index-tsx" */),
  "component---src-pages-manager-tsx": () => import("./../../../src/pages/manager.tsx" /* webpackChunkName: "component---src-pages-manager-tsx" */),
  "component---src-pages-preferences-tsx": () => import("./../../../src/pages/preferences.tsx" /* webpackChunkName: "component---src-pages-preferences-tsx" */),
  "component---src-pages-services-ai-index-tsx": () => import("./../../../src/pages/services/ai/index.tsx" /* webpackChunkName: "component---src-pages-services-ai-index-tsx" */),
  "component---src-pages-services-mobile-index-tsx": () => import("./../../../src/pages/services/mobile/index.tsx" /* webpackChunkName: "component---src-pages-services-mobile-index-tsx" */),
  "component---src-pages-services-next-gen-index-tsx": () => import("./../../../src/pages/services/next-gen/index.tsx" /* webpackChunkName: "component---src-pages-services-next-gen-index-tsx" */),
  "component---src-pages-services-web-index-tsx": () => import("./../../../src/pages/services/web/index.tsx" /* webpackChunkName: "component---src-pages-services-web-index-tsx" */),
  "component---src-pages-terms-license-tsx": () => import("./../../../src/pages/terms/license.tsx" /* webpackChunkName: "component---src-pages-terms-license-tsx" */),
  "component---src-pages-terms-website-tsx": () => import("./../../../src/pages/terms/website.tsx" /* webpackChunkName: "component---src-pages-terms-website-tsx" */)
}

