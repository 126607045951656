/* eslint-disable no-restricted-imports */
import { Button } from '@/lib'
import { Theme } from '@/app'
import { Assets } from '@/assets'

export type PortfolioItemProps = {
  title: string
  slug: string
  subtitle: string
  logo: string
  body: string[]
  background: string
  images: string[]
  display?: () => JSX.Element
}

const becon = {
  title: 'Becon',
  slug: 'becon',
  subtitle: 'Automated Walking Safety',
  body: [
    `Becon is an emergency alert app for walking journeys that uses automatic triggers to keep its users safe, whilst maintaining their desire 
    for independence and privacy.`,
  ],
  logo: Assets.Portfolio.Becon['transparent-logo'],
  companies: [
    Assets.Logos.MarieClaire,
    Assets.Logos.Forbes,
    Assets.Logos.Metro,
    Assets.Logos.EveningStandard,
  ],
  background: Assets.Portfolio.Becon.background,
  button: () => {
    return (
      <Button
        gaLabel={'Portfolio'}
        gaAction={`/becontheapp.com from Becon`}
        variant={'inverted uppercase'}
        text={'Learn more'}
        to={'https://becontheapp.com/'}
      />
    )
  },
}

const gqa = {
  title: 'Go Quest Adventure',
  slug: 'go-quest-adventures',
  subtitle: 'DISCOVER, EXPLORE, CONQUER',
  body: [
    'Go Quest Adventures is a mobile app that offers fun, challenging and interactive City Quests, all self guided through its friendly mobile app.',
  ],
  logo: Assets.Portfolio.GoQuest.logo,
  background: Assets.Portfolio.GoQuest.background,
}

const kite = {
  title: 'Kite Insights',
  slug: 'kite',
  subtitle: 'ESG CORPORATE TRANSPARENCY',
  logo: Assets.Portfolio.Kite.logo,
  body: [
    `Kite Insights is a leading ESG consultancy firm in London who teamed up with CodeLeap to launch Hurd – a mobile app that brings
     transparency to large organisations’ climate policies and give employees a voice.`,
  ],
  extraStyles: {
    logo: {
      width: 100,
      height: 100,
      marginBottom: Theme.spacing(-0.5),
    },
  },
  background: Assets.Portfolio.Kite.background,
}

export const apps: { [key: string]: PortfolioItemProps } = {
  'autoimaging-mobile': {
    title: 'AutoImaging',
    slug: 'autoimaging',
    subtitle: '100% AI Automated Video',
    logo: Assets.Portfolio.Autoimaging.logo,
    body: [
      `AutoImaging is a leading provider of car imaging solutions for the auto industry. Its AI Imagery Software uses Computer Vision techniques 
      to offer real-time imaging quality control, automated backdrops replacement and 100% automated and bespoke branded video production.`,
    ],
    background: Assets.Portfolio.Autoimaging.background,
    images: [
      Assets.Portfolio.Autoimaging.mobile,
    ],
  },
  'cloudroom-web': {
    title: 'AWS CloudRoom',
    slug: 'aws-cloudroom',
    subtitle: 'VOLUNTEERING EVENTS PLATFORM',
    body: [
      'CloudRoom is Amazon’s global initiative for helping students around the world learn more about the Cloud and its career opportunities.',
    ],
    logo: Assets.Logos['aws-white-logo'],
    extraStyles: {
      logo: {
        width: 65,
        height: 65,
        marginBottom: Theme.spacing(-0.5),
      },
    },
    doNotShowAppStoreLogos: true,
    background: Assets.Portfolio.AWSCloudroom.background,
    images: [
      Assets.Portfolio.AWSCloudroom[1],
      Assets.Portfolio.AWSCloudroom[2],
      Assets.Portfolio.AWSCloudroom[3],
    ],
  },
  'aws-girls-tech-series-web': {
    title: 'AWS Girls Tech Series',
    slug: 'aws-girls-tech-series',
    subtitle: 'ESG EVENTS PLATFORM',
    body: [
      `In partnership with Marble LND, CodeLeap developed Amazon’s Girls Tech Series program, a free learning experience that focuses on each of 
      the STEAM areas with activities designed to engage, connect and activate future female innovators.`,
    ],
    logo: Assets.Logos['aws-white-logo'],
    doNotShowAppStoreLogos: true,
    background: Assets.Portfolio.AWSGirlsTechSeries.background,
    images: [
      Assets.Portfolio.AWSGirlsTechSeries[1],
      Assets.Portfolio.AWSGirlsTechSeries[2],
      Assets.Portfolio.AWSGirlsTechSeries[3],
    ],
  },
  'becon-mobile': {
    ...becon,
    images: [
      Assets.Portfolio.Becon.mobile[1],
      Assets.Portfolio.Becon.mobile[2],
      Assets.Portfolio.Becon.mobile[3],
    ],
  },
  'becon-web': {
    ...becon,
    images: [
      Assets.Portfolio.Becon.web[1],
      Assets.Portfolio.Becon.web[2],
      Assets.Portfolio.Becon.web[3],
    ],
  },
  'bearable-mobile': {
    title: 'Bearable',
    slug: 'bearable',
    subtitle: 'Easy Health Diary With Stats',
    body: [
      'Bearable is a mobile app that helps people keep track of their health and well-being.',
    ],
    logo: Assets.Portfolio.Bearable['logo-2'],
    companies: [
      Assets.Logos.WebMD,
      Assets.Logos.USPainFoundation,
      Assets.Logos.Forbes,
      Assets.Logos.PsychCentral,
    ],
    background: Assets.Portfolio.Bearable.background,
    images: [
      Assets.Portfolio.Bearable[1],
      Assets.Portfolio.Bearable[2],
      Assets.Portfolio.Bearable[3],
    ],
  },
  'cherry-mobile': {
    title: 'Cherry Dating',
    slug: 'cherry',
    subtitle: 'FACIAL RECOGNITION FOR USER SAFETY',
    body: [
      `Cherry is a dating app that keeps all  members safe by verifying their accounts using an official ID and security selfie,
       while cross-checking those images using facial recognition against users’ own profile photos within the app.`,
    ],
    logo: Assets.Portfolio.Cherry['logo-1'],
    background: Assets.Portfolio.Cherry.background,
    images: [
      Assets.Portfolio.Cherry.mobile[1],
      Assets.Portfolio.Cherry.mobile[2],
      Assets.Portfolio.Cherry.mobile[3],

    ],
  },
  'go-quest-mobile': {
    ...gqa,
    images: [
      Assets.Portfolio.GoQuest.mobile[1],
      Assets.Portfolio.GoQuest.mobile[2],
      Assets.Portfolio.GoQuest.mobile[3],
    ],
  },
  'go-quest-web': {
    ...gqa,
    images: [
      Assets.Portfolio.GoQuest.web[1],
      Assets.Portfolio.GoQuest.web[2],
      Assets.Portfolio.GoQuest.web[3],
    ],
  },
  'kite-general': {
    ...kite,
    images: [
      Assets.Portfolio.Kite.mobile[1],
      Assets.Portfolio.Kite.mobile[2],
      Assets.Portfolio.Kite.mobile[3],

    ],
  },
  'kite-mobile': {
    ...kite,
    images: [
      Assets.Portfolio.Kite.mobile[1],
      Assets.Portfolio.Kite.mobile[2],
      Assets.Portfolio.Kite.mobile[3],
    ],
  },
  'kite-web': {
    ...kite,
    images: [
      Assets.Portfolio.Kite.web[1],
      Assets.Portfolio.Kite.web[2],
      Assets.Portfolio.Kite.web[3],
    ],
  },
  'skamper-mobile': {
    title: 'Skamper',
    slug: 'skamper',
    subtitle: 'SHARING THE JOY OF RUNNING',
    body: [
      'Skamper is a mobile app that encourage runners to test themselves against the targets that matter to them, at a time that suits them.',
    ],
    logo: Assets.Portfolio.Skamper.logo,
    background: Assets.Portfolio.Skamper.background,
    images: [
      Assets.Portfolio.Skamper.mobile[1],
      Assets.Portfolio.Skamper.mobile[2],
      Assets.Portfolio.Skamper.mobile[3],
    ],
  },
}
