import { Theme, ViewStyles } from '@/app'

const text = {
  ...ViewStyles,
  // Typography
  get default() {
    return {
      ...Theme.row,
      ...this.p1,
      color: '#333',
      fontFamily: Theme.fontFamily,
    }
  },
  h1: {
    fontSize: 'max(38px, min(4vw, 96px))',
    color: '#000',
    fontWeight: '800',
    fontFamily: Theme.headerFontFamily,
    letterSpacing: '-2px',
    lineHeight: '1.05',
    [Theme.media.down('mid')]: {
      paddingRight: 4,
    },
  },
  h2: {
    fontSize: 'max(30px, min(calc(26px + 2vw), 74px))',
    color: '#222',
    fontWeight: '600',
    fontFamily: Theme.headerFontFamily,
    letterSpacing: '-2px',
    lineHeight: '1.15',
    [Theme.media.down('mid')]: {
      paddingRight: 4,
    },
  },
  h3: {
    fontSize: 'max(26px, min(calc(20px + 1.6vw), 54px))',
    fontWeight: '500',
    fontFamily: Theme.headerFontFamily,
    lineHeight: '1.15',
  },
  h4: {
    fontSize: 'max(22px, min(calc(14px + 1vw), 34px))',
    fontWeight: '500',
    lineHeight: '1.25',
  },
  h5: {
    fontSize: 'max(20px, min(calc(12px + 0.65vw), 28px))',
  },
  h6: {
    fontSize: 'max(17px, min(calc(8px + 0.65vw), 21px))',
  },
  p1: {
    lineHeight: '1.4',
    fontSize: 'max(15px, min(calc(2.3px + 0.95vw), 18px))',
  },
  'p1:responsive': {
    lineHeight: '1.4',
    fontSize: 'max(12px, min(calc(5px + 0.7vw), 18px))',
  },
  p2: {
    lineHeight: '1.4',
    fontSize: 'max(12px, min(calc(6px + 0.5vw), 15px))',
  },
  p3: {
    lineHeight: '1.4',
    fontSize: 'max(11px, min(calc(8.5px + 0.2vw), 12px))',
  },

  // Aditional styles
  inherit: {
    fontWeight: 'inherit',
    fontSize: 'inherit',
    color: 'inherit',
    display: 'inherit',
    textDecorationStyle: 'inherit',
    textDecorationColor: 'inherit',
    textDecorationLine: 'inherit',
  },
  veryBold: {
    fontWeight: '700',
  },
  semiBold: {
    fontWeight: '600',
  },
  medium: {
    fontWeight: '500',
  },
  bold: {
    fontWeight: 'bold',
  },
  normal: {
    fontWeight: 'normal',
  },
  thin: {
    fontWeight: '300',
  },
  veryThin: {
    fontWeight: '100',
  },
  tall: {
    lineHeight: '1.6',
  },
  italic: {
    fontStyle: 'italic',
  },
  link: {
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
    cursor: 'pointer !important',
    '&:hover': {
      textDecorationLine: 'underline !important',
    },
  },
  noDecoration: {
    textDecorationLine: 'none',
  },
  noWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    'text-overflow': 'ellipsis',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  hoverDecoration: {
    textDecorationLine: 'none',
    '&:hover': {
      textDecorationStyle: 'solid !important',
      textDecorationLine: 'underline',
    },
  },
  underlineDecoration: {
    textDecorationLine: 'underline',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  transition: {
    transition: '500ms',
  },
  justifyWords: {
    textJustify: 'inter-word',
    textAlign: 'justify',
  },
  justifyCenter: {
    textAlign: 'center',
  },
  space: {
    paddingLeft: 4,
  },
  narrow: {
    letterSpacing: '-2px',
  },

  // Colors
  white: {
    color: Theme.colors.white,
    textDecorationColor: Theme.colors.white,
  },
  offwhite: {
    color: Theme.colors.offwhite,
    textDecorationColor: Theme.colors.offwhite,
  },
  neutral2: {
    color: Theme.colors.neutral2,
    textDecorationColor: Theme.colors.neutral2,
  },
  neutral3: {
    color: Theme.colors.neutral3,
    textDecorationColor: Theme.colors.neutral31,
  },
  neutral4: {
    color: Theme.colors.neutral4,
    textDecorationColor: Theme.colors.neutral4,
  },
  shadow: {
    textShadow: '2px 2px 20px #00000033',
  },
  grey: {
    color: Theme.colors.grey,
    textDecorationColor: Theme.colors.grey,
  },
  dark: {
    color: Theme.colors.midDark,
    textDecorationColor: Theme.colors.midDark,
  },
  charcoalGrey: {
    color: Theme.colors.charcoalGrey,
    textDecorationColor: Theme.colors.charcoalGrey,
  },
  lightGrey: {
    color: Theme.colors.lightGrey,
    textDecorationColor: Theme.colors.lightGrey,
  },
  light: {
    color: Theme.colors.light,
    textDecorationColor: Theme.colors.light,
  },
  lightish: {
    color: Theme.colors.lightish,
    textDecorationColor: Theme.colors.lightish,
  },
  brown: {
    color: Theme.colors.brown,
    textDecorationColor: Theme.colors.brown,
  },
  darkest: {
    color: Theme.colors.darkest,
    textDecorationColor: Theme.colors.darkest,
  },
  black: {
    color: Theme.colors.black,
    textDecorationColor: Theme.colors.black,
  },
  black75: {
    color: Theme.colors.black75,
    textDecorationColor: Theme.colors.black75,
  },
  neutralGrey: {
    color: Theme.colors.neutralGrey,
    textDecorationColor: Theme.colors.neutralGrey,
  },
  neutralWhite: {
    color: Theme.colors.neutralWhite,
    textDecorationColor: Theme.colors.neutralWhite,
  },
  primary: {
    color: Theme.colors.primarySolid,
    background: Theme.colors.primary,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecorationColor: Theme.colors.primary,
    display: 'inline-block',
    transition: '500ms',
  },
  primaryOpacity: {
    color: Theme.colors.primarySolid,
    background: Theme.colors.primary,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textDecorationColor: Theme.colors.primary,
    display: 'inline-block',
    transition: '500ms',
    opacity: 0.5,
  },
  primaryBright: {
    color: Theme.colors.primaryBright,
  },
  blue: {
    color: Theme.colors.blue,
  },
  debug: {
    ...Theme.debug('rgba(50, 100, 255, 0.5)'),
  },
}

const compatibility = {
  ...text,
  // Typography
  default: {
  },
  h1: {
    fontSize: '3rem',
  },
  h2: {
    fontSize: '2rem',
  },
  h3: {
    fontSize: '1.5rem',
  },
  h4: {
    fontSize: '1.3rem',
  },
  h5: {
    fontSize: '1.2rem',
  },
  h6: {
    fontSize: '1.1rem',
  },
  p1: {
    fontSize: '16px',
  },
  p2: {
    fontSize: '14px',
  },
  p3: {
    fontSize: '13px',
  },
}

export default {
  text,
  compatibility,
}
